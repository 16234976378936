<template>
<div>

<div class="demo-inline-spacing">
 <div v-if="liveData">
        <b-button
            @click="liveData = false"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            pill
        >
         Turn Live Data OFF
        </b-button>
</div>
<div v-else>
        <b-button
            @click="liveData = true"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
        >
         Turn Live Data On
        </b-button>
</div>

    <b-button
      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="outline-info"
      pill
      @click="synchronization"
    >
      Synchronize <b-spinner
        v-if="syncing"
        small
        class="mr-1"
        label="Small Spinner"
      />
    </b-button>
 <div v-if="liveData">
    <div @click="liveData = false">
        Turn OFF Live data {{liveData}}
    </div>
</div>
<div v-else>
    <div @click="liveData = true">
        Turn On Live data {{liveData}}
    </div>
</div>

    <b-button
      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="outline-warning"
      pill
    >
        Detected Duplicates  <b-badge variant="light-danger"> {{ ordersList.length }} </b-badge>
    </b-button>
</div>

<div class="order-table">
    
Selected: {{ this.selectedOrders() }}

    <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :gridOptions="gridOptions" :columnDefs="columnDefs" :rowData="ordersList" rowSelection="multiple" rowMultiSelectWithClick="true" @selection-changed="getSelectedRows" :animateRows="true" :floatingFilter="true">
    </ag-grid-vue>
</div>


</div>
</template>
<script>
import {
    mapGetters
} from "vuex";
import {
    AgGridVue
} from "ag-grid-vue";
import { BButton, BBadge, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OrderCart from '../components/OrderCart'

export default {

    name: 'App',
    data() {
        return {
            rowData: null,
            liveData: false,
            syncing: false,
            selected: []
        }
    },

    components: {
        AgGridVue,
        BButton,
        BBadge,
        BSpinner,
        OrderCart
    },
    directives: {
        Ripple,
    },
    computed: {
        isDisabled: function(){
    	    return !this.liveData;
        },
        ...mapGetters(["ordersList"]),
    },

    mounted() {
        this.gridApi = this.gridOptions.api;
            window.Echo.channel("addOrder").listen(".order-created", (order) => {
                if(this.liveData){
                    this.$store.commit("ADD_ORDER", order);
                    this.synchronization(); // always Synchronize when order received
                }
            });
    },

    created() {
        this.$store.dispatch("GET_ORDERS");
    },

    beforeDestroy(){
        window.Echo.leave('addOrder');
    },

    methods: {
        selectedOrders(selectedDataStringPresentation){
            return selectedDataStringPresentation;
        },
        synchronization() {
            this.syncing = true;
            this.setTimeout(() => {
                this.syncing = false
            })
        },

        setTimeout(callback) {
            this.$store.dispatch("GET_ORDERS");
            setTimeout(() => {
            callback();
            }, 1200)
        },

        getStatusColor(params) {
            return {color: params.data.status_font_color, backgroundColor: params.data.status_back_color};
        },
        getStatus(params) {
            // here make a request for status change
    
            // window.Echo.channel("order." + params.data.id).listen(".order-details", (order) => {
            //     this.$store.commit("ADD_ORDER", order);
            // });


            return params.value;
        },
        getAPIStatusColor(params) {
            // here make a request for status value and get colors
            return {color: params.data.status_api_font_color, backgroundColor: params.data.status_api_back_color};
        },
        getAPIStatus(params) {
            // here make a request for status value and get colors
            return params.value;
        },

        getCart(params) {
            return "<div>test</div>";
        },

        link(params) {
            return router.push({ path: 'register', query: { plan: 'private' } });
        },

        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        },
        
        getSelectedRows() {
            const selectedNodes = this.gridApi.getSelectedRows();
            const selectedData = selectedNodes.map(node => node);
            const selectedDataStringPresentation = selectedData.map(node => node.id).join(', ');
            // this.selectedOrders = selectedDataStringPresentation;

            this.selectedOrders(selectedDataStringPresentation);
            this.$store.state.selectedOrders = selectedDataStringPresentation;
        },
    },


    beforeMount() {
        this.gridOptions = {};
        this.columnDefs = [
             {
                rowMultiSelectWithClick: true,
                headerName: 'ID',
                field: 'id',
                filter: true,
                maxWidth: 50,
                resizable: true,
                pinned: true,
                 cellRenderer: (params) => {
                    const route = {
                    name: "view-order",
                        params: { id: params.value }
                    };

                    const link = document.createElement("a");
                    link.href = this.$router.resolve(route).href;
                    link.innerText = params.value;
                    link.addEventListener("click", e => {
                    e.preventDefault();
                    this.$router.push(route);
                    });
                    return link;
                }

            },
            {
                rowMultiSelectWithClick: true,
                headerName: 'Status',
                field: 'status',
                sortable: true,
                filter: true,
                maxWidth: 100,
                resizable: true,
                editable: true,
                pinned: true,
                cellStyle: params => {
                    return this.getStatusColor(params);
                },
                cellRenderer: params => {
                    return this.getStatus(params);
                },

            },
            {
                headerName: 'Status API',
                field: 'status_api',
                sortable: true,
                filter: true,
                maxWidth: 100,
                resizable: true,
                editable: true,
                pinned: true,
                cellStyle: params => {
                    return this.getAPIStatusColor(params);
                },
                cellRenderer: params => {
                    return this.getAPIStatus(params);
                }
            },
            {
                headerName: 'Cart',
                field: 'cart',
                sortable: true,
                maxWidth: 100,
                resizable: true,
                pinned: true,
                cellRenderer: 'OrderCart'
                // cellRenderer: params => {
                //     return this.getCart(params);
                // }
            },
            // {
            //     headerName: 'Lang',
            //     field: 'lang',
            //     sortable: true,
            //     filter: true,
            // },
            {
                headerName: 'Country',
                field: 'country',
                sortable: true,
                filter: true,
                maxWidth: 130,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Product',
                field: 'product',
                sortable: true,
                filter: true,
                maxWidth: 170,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Date',
                field: 'date',
                sortable: true,
                filter: true,
                resizable: true
            },
            {
                headerName: 'Notes',
                field: 'comment',
                sortable: true,
                filter: true,
                maxWidth: 150,
                resizable: true,
                editable: true,
            },
            // {
            //     headerName: 'Browser',
            //     field: 'browser',
            //     sortable: true,
            //     filter: true,
            //     maxWidth: 130,
            //     resizable: true
            // },
            // {
            //     headerName: 'IP',
            //     field: 'ip',
            //     sortable: true,
            //     filter: true,
            //     maxWidth: 160,
            //     resizable: true
            // },
            {
                headerName: 'Product page',
                field: 'page',
                sortable: true,
                filter: true,
                resizable: true
            },
            {
                headerName: 'Traffic',
                field: 'traffic',
                sortable: true,
                filter: true,
                maxWidth: 170,
                resizable: true
            },
            {
                headerName: 'Quantity',
                field: 'qty',
                sortable: true,
                filter: true,
                maxWidth: 130,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Price',
                field: 'total_price',
                sortable: true,
                filter: true,
                maxWidth: 150,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Name',
                field: 'name',
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Address',
                field: 'address',
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
            },
            // {
            //     headerName: 'Province',
            //     field: 'province',
            //     sortable: true,
            //     filter: true,
            //     maxWidth: 150,
            //     resizable: true,
            //     editable: true,
            // },
            {
                headerName: 'City',
                field: 'city',
                sortable: true,
                filter: true,
                maxWidth: 150,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'ZIP',
                field: 'zip',
                sortable: true,
                filter: true,
                maxWidth: 110,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Telephone',
                field: 'telephone',
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Email',
                field: 'email',
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: 'Courier Date',
                field: 'courier date',
                sortable: true,
                filter: true,
                resizable: true
            },
            {
                headerName: 'Courier Name',
                field: 'courier name',
                sortable: true,
                filter: true,
                resizable: true
            },
        ];
    },
}
</script>

<style lang="scss">
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

// .ag-theme-alpine {
//     /* use theme parameters where possible */
//     --ag-foreground-color: #000;
// }

// /* or write CSS selectors to make customisations beyond what the parameters support */
// .ag-theme-alpine .ag-header {
//     text-style: italic;
// }

// .ag-header-container {
//   background-color: #000;
//   background-color: var(--ag-foreground-color, #000);
//   color: #fff;
//   color: var(--ag-background-color, #fff);
// }
// .ag-root-wrapper .ag-layout-normal{
//     background: #000;
// }


.order-table{
    position: relative;
    margin-top: 20px;
}
</style>